<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4><i class="fal fa-users"></i> All Vendors</h4>
          <hr>
          <v-client-table :columns="columns" :data="vendorData" :options="options" :theme="theme" id="dataTable">
            <div slot="edit" slot-scope="props">
              <b-button v-b-modal.editVendor @click="sendVendorId(props.row.vid)" class="btn-sm mr-2" variant="success">Edit Password <i class="fa fa-edit"></i></b-button>
              <b-button v-b-modal.editVendorEmail @click="sendVendorId(props.row.vid, props.row.email)" class="btn-sm" variant="success">Edit Email <i class="fa fa-edit"></i></b-button>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="editVendor" ref="editVendor" title="Edit Vendor Password" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="passwordvalidateBeforeSubmit" class="pb-2">
        <p class="text-muted">Enter password and confirmation</p>
        <b-row class="mb-3">
          <b-col md="12 mb-2">
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
              </b-input-group-prepend>
              <input type="password" class="form-control" v-model="password" placeholder="Password" name="password" ref="password"/>
            </b-input-group>
          </b-col>
          <b-col md="12">
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
              </b-input-group-prepend>
              <input type="password" class="form-control" v-model="password2" placeholder="Password Confirmation" data-vv-as="password" name="password2" />
            </b-input-group>
          </b-col>
        </b-row>
        <i v-show="passworderror" class="fa fa-exclamation-triangle"></i><span v-show="passworderror" class="help is-danger">Error. Weak password or do not match. Try again.</span>

        <b-button variant="success" class="mt-4" type="submit" block>Edit Password</b-button>
      </form>
    </b-modal>

    <b-modal id="editVendorEmail" ref="editVendorEmail" title="Edit Vendor Email" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="emailvalidateBeforeSubmit('editemail')" class="pb-2">
        <p class="text-muted">Enter new email.</p>
        <b-row class="mb-3">
          <b-col md="12">
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-envelope"></i></b-input-group-text>
              </b-input-group-prepend>
              <input type="email" class="form-control" v-model="email" placeholder="Email Address" name="email" ref="email"/>
            </b-input-group>
          </b-col>
        </b-row>
        <i v-show="emailerror" class="fa fa-exclamation-triangle"></i><span v-show="emailerror" class="help is-danger">Error. Please try again.</span>

        <b-button variant="success" class="mt-4" type="submit" block>Edit Email</b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
Vue.use(ClientTable)

export default {
  name: 'WtsVendor',
  components: {
    ClientTable,
    Event,
  },
  data () {
    return {
      columns: ['fname', 'lname', 'email', 'vidcode',  'edit'],
      options: {
        headings: {
          fname: 'First Name',
          lname: 'Last Name',
          vidcode: 'Vendor ID',
        },
        sortable: ['companyid', 'email', 'fname', 'lname', 'vidcode'],
        filterable: ['companyid', 'email', 'fname', 'lname' , 'vidcode'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 15,
      },

      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      password: '',
      password2: '',
      error: false,
      passworderror: false,
      passlimit: false,
      vendorData: [],
      vid: 0,
      email: "",
      oldemail: "",
      emailerror: false
    }
  },
  methods: {
    sendVendorId(vid, email){
      this.vid = vid;
      this.email = email;
      this.oldemail = email;
    },
    passwordvalidateBeforeSubmit() {
      let data = JSON.stringify({
        id: this.vid,
        password: this.password,
        confirm_password: this.password2,
      })
      this.$http.post('/admin/edit/vendor_password', data)
      .then(response => {
        this.$toasted.show('Password Updated', {icon: 'fa-check', type: 'success', duration: '3000'})
        this.$refs.editVendor.hide();
        this.password = "";
        this.password2 = "";
      })
      .catch(error => {
        this.passworderror = true;
      })
      return;
    },
    emailvalidateBeforeSubmit() {
      let data = JSON.stringify({
        vid: this.vid,
        oldemail: this.oldemail,
        newemail: this.email,
      })
      this.$http.post('/vendor/edit/email', data)
      .then(response => {
        this.loadData();
        this.$toasted.show('Email Updated', {icon: 'fa-check', type: 'success', duration: '3000'})
        this.$refs.editVendorEmail.hide();
        this.email = "";
        this.emailerror = false;
      })
      .catch(error => {
        this.emailerror = true;
      })
      return;
    },
    loadData() {
      this.$http.get('/admin/get/vendors')
      .then(response => {
        this.vendorData = response.data.result;
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    },
  },
  mounted: function () {
    this.loadData();
  },
}
</script>
<style>
  .VueTables__limit,
  .VueTables__search {
    display: none !important;
  }
</style>
